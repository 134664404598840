import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { saveAs } from 'file-saver';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch(() => {
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch(() => {
        return [];
    })
}


export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reporteKardexValorizado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let moneda = data.moneda;
    let tipo = data.tipoCliente
    let reporte = 'kardex_valorizado';
    let producto = data.producto ? data.producto.value : '';
    let modulo = 'INV';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, moneda, tipo, producto, modulo, bodega };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_kardex_valorizado.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
} 

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const reporteMovimiento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let producto = data.producto ? data.producto.value : '';
    let reporte = 'movimiento';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, producto, bodega };
    
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_trazabilidad_produccion.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteExistencia = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let producto = data.producto ? data.producto.value : '';
    let reporte = 'existencia';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, producto, bodega };
    
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_trazabilidad_produccion.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteInventarioFisico = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values 
    let reporte = 'inventario_fisico';
    let bodega = data ? data.bodega
                    ? data.bodega.value 
                    : '' 
                :''
    const params = { id, reporte, bodega};
    
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_inventario_fisico.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteSalidasDeBodegas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'salidas_de_bodega';
    const params = { id, reporte, fechaI, fechaF, bodega };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'salidas_de_bodega.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                "No se encontraron datos",
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
  
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelSalidasDeBodegas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'excel_salidas_de_bodega';
    const params = { id, reporte, fechaI, fechaF, bodega };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'salidas_de_bodega.xlsx';
        
        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
        
    }).catch(error => {
        if (error === 404) {
            NotificationManager.info(
                "No se encontraron datos",
                'Informacion',
                5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
        );
    }})
    .finally(() => {
        dispatch(setLoader(false));
    });
        
}
    
export const reporteEntradasABodegas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'entradas_a_bodega';
    const params = { id, reporte, fechaI, fechaF, bodega };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'salidas_de_bodega.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                "No se encontraron datos",
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const reporteExistenciaReservaOS = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let producto = data.producto ? data.producto.value : '';
    let reporte = 'existencia_reserva_os';
    const params = { id, reporte, fechaI, fechaF, producto };
    
    dispatch(setLoader(true));
    api.getPdf('/inv_consumo_producto', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_productos_reserva.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelExistencia = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let producto = data.producto ? data.producto.value : '';
    let reporte = 'excel_existencia';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, producto, bodega };
    
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'existencia_productos.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    
    }).catch(error => {
        if (error === 404) {
        NotificationManager.error(
            "No se encontraron datos",
            'Informacion',
            5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelEntradasBodegas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let bodega = data.bodega ? data.bodega.value : '';
    let reporte = 'excel_entradas_a_bodega';
    const params = { id, reporte, fechaI, fechaF, bodega };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'entradas_bodega.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    
    }).catch(error => {
        if (error === 404) {
        NotificationManager.error(
            "No se encontraron datos",
            'Informacion',
            5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteVentasDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
  
    let reporte = "ventas_detalle";
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let cliente = data.cliente ? data.cliente.value : '';
    const params = { id, reporte, cliente, fechaI, fechaF };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
      if (response.headers['content-type'] == 'application/pdf') {
        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'ventas_detalle';
        window.open(link, '_blank');
        return false;
      }
    }).catch((error) => {
      if (error.status === 404) {
        NotificationManager.error(
          "No se encontraron datos",
          'Error',
          5000
        );
      }
      if (error.status === 500) {
        NotificationManager.error(
          'Ocurrió un error al generar reporte',
          'Error',
          0
        );
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
      const list_cliente = [];
      if (data) {
        data.forEach(item => {
          list_cliente.push({
            value: item.id,
            label: item.identificador + ' ' + item.nombre
          })
        })
      }
      return list_cliente;
    }).catch(() => {
      return [];
    })
}

export const excelVentasDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let reporte = 'excel_ventas_detalle';
    let cliente = data.cliente ? data.cliente.value : '';
    const params = { id, reporte, cliente, fechaI, fechaF };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'ventas_detalle.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    
    }).catch(error => {
        if (error === 404) {
        NotificationManager.error(
            "No se encontraron datos",
            'Informacion',
            5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteKardex = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let moneda = data.moneda;
    let tipo = data.tipoCliente
    let reporte = 'kardex';
    let producto = data.producto ? data.producto.value : '';
    let modulo = 'INV';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, moneda, tipo, producto, modulo, bodega };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_kardex.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelKardex = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let moneda = data.moneda;
    let tipo = data.tipoCliente
    let reporte = 'excel_kardex';
    let producto = data.producto ? data.producto.value : '';
    let modulo = 'INV';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, moneda, tipo, producto, modulo, bodega };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'kardex.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    
    }).catch(error => {
        if (error === 404) {
        NotificationManager.error(
            "No se encontraron datos",
            'Informacion',
            5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelKardexValorizado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let moneda = data.moneda;
    let tipo = data.tipoCliente
    let reporte = 'excel_kardex_valorizado';
    let producto = data.producto ? data.producto.value : '';
    let modulo = 'INV';
    let bodega = data.bodega ? data.bodega.value : '';
    const params = { id, reporte, fechaI, fechaF, moneda, tipo, producto, modulo, bodega };
    dispatch(setLoader(true));
    api.getExcel('/movimiento_inventario', params).then((response) => {
        
        const blob = response;
        const fileName = 'kardex_valorizado.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
    
    }).catch(error => {
        if (error === 404) {
        NotificationManager.error(
            "No se encontraron datos",
            'Informacion',
            5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarCuentas,
    listarCC,
    listarMonedas,
    getModulo,
    listarProductos,
    reporteKardexValorizado,
    listarBodegas,
    reporteExistencia,
    reporteInventarioFisico,
    reporteSalidasDeBodegas,
    reporteEntradasABodegas,
    excelSalidasDeBodegas,
    parametrosEmpresa,
    reporteExistenciaReservaOS,
    reporteMovimiento,
    excelExistencia,
    excelEntradasBodegas,
    listarClientes,
    excelVentasDetalle,
    reporteVentasDetalle,
    reporteKardex,
    excelKardex,
    excelKardexValorizado
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    monedas: [],
    modulo: [],
    datos: []
};

export default handleActions(reducers, initialState)