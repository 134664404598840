import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from 'react-icons';
import styled from 'styled-components';
import { SidebarDataUserS } from '../Sidebar/SideBarDataUserS';
import MenuItemsUser from './NavbarMenuUser';
import { SidebarData } from '../Sidebar/SidebarDataTools';
import MenuItems from './NavbarMenuTools';
import SubMenu from '../Sidebar/SubmenuInventario';

const defaultAvatar = require("assets/img/avatar-placeholder.png");

function NavbarTools(props) {
    const {
        logOut,
        user,
    } = props;

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => {
        setSidebar(!sidebar);
    }
    return (
        <React.Fragment>
            <IconContext.Provider value={{ color: '#fff' }}>
                <NavBar>
                <div className="imageBoxLogo d-table m-auto">
                    <Image
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" />
                </div>
                <NavBarMenuUser>
                    <ul className="borderUser flex-row navbar-nav">
                        {SidebarDataUserS.map((menu, index) => {
                            return <MenuItemsUser
                                items={menu}
                                key={index}
                                img={(user.avatar) ? __CONFIG__.api_image + user.avatar : defaultAvatar}
                                user={user.username}
                                logOut={logOut}
                            />;
                        })}
                    </ul>
                </NavBarMenuUser>
		</NavBar>
                <NavBarMenu>
                    <ul className="menus flex-row navbar-nav">
                        {SidebarData.map((menu, index) => {
                            return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                </NavBarMenu>
                <SidebarNav sidebar={sidebar}>
                    <SiderbarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIcon>
                        {SidebarData.map((item, index) => {
                            return <SubMenu item={item} key={index} />;
                        })}
                    </SiderbarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </React.Fragment>
    );
}

export default NavbarTools;

const NavBar = styled.div`
    background-color: #333;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenuUser = styled.div`
    background-color: black;
    height: 61px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenu = styled.div`
    background-color: black;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
`;

const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: none;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    @media (max-width: 402px) {
        display: none;
        
    }
`
const SidebarNav = styled.nav`
    background: #333;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
`;

const SiderbarWrap = styled.div`
    width: 250px;
`;
